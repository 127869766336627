import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import ParseContent from 'components/shared/ParseContent'
import BackgroundImage from 'components/shared/BackgroundImage'
import Image from 'components/shared/Image'

import DefaultBackgroundImage from 'img/background-default.jpg'

// Images
import ArrowRight from 'img/arrow-right.inline.svg'
import QuoteBalloon from 'components/elements/QuoteBalloon'



const StyledArrowRight = styled(ArrowRight)`
  transform: rotate(180deg);
  width: 15px;
  height: 15px;
  margin-right: 10px;
`

const BannerWrapper = styled.section<{ expand: boolean }>`
  position: relative;

  ${(props) =>
    props.expand
      ? css`
          min-height: 300px;
          padding-top: 35px;
          padding-bottom: 35px;
          margin-bottom: 5rem;
        `
      : css`
          height: 300px;
          margin-bottom: 10rem;
        `};
  & .container,
  & .row,
  & .col-5,
  & .col-7 {
    height: inherit;
  }

  @media screen and (max-width: 991px) {
    height: fit-content;
    margin-bottom: 1rem;
  }
`

const StyledImage = styled(Image)`
  bottom: 60px;
  right: 20px;
  position: absolute !important;
`

const PersonImage = styled(Image)`
  bottom: -60px;
  left: 0;
  position: absolute !important;
  width: 80%;
  height: auto;

  @media screen and (max-width: 1199px) {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    width: 90%;
    left: 10%;
    bottom: -30px;
  }
`

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_BannerDefault
  location?: any
  backButton: {
    to: string
  }
}

const BackButton = styled(Link)`
  position: absolute;
  z-index: 10;
  top: 15px;
  left: 0;
  color: ${({ theme }) => theme.colors.light};

  &:hover {
    color: ${({ theme }) => theme.colors.light};
  }

  @media screen and (max-width: 991px) {
    position: relative;
  }
`

const Content = styled(ParseContent)`
  & h1 {
    font-size: 70px;
  }

  @media screen and (max-width: 991px) {
    & h1 {
      font-size: 40px;
      padding-bottom: 15px;
    }
  }
`

const PersonCard = styled.div`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
`


const BannerDefault: React.FC<BannerDefaultProps> = ({
  fields,
  backButton,
}) => (
  <BannerWrapper
    className="d-flex align-items-center"
    expand={!fields.image && !fields.column?.description}
  >
  <BackgroundImage
      loading="eager"
      alt="banner"
      staticSource={!!fields.background === false}
      image={fields.background ? fields.background : DefaultBackgroundImage}
    />
    <div
      className="container position-relative z-index-2"
      style={{ height: 'inherit' }}
    >
      {!!backButton && (
        <BackButton to={backButton.to}>
          <StyledArrowRight /> Terug naar het overzicht
        </BackButton>
      )}
      <div className="row py-lg-0 py-4">
        <div className="col-lg-4 pt-lg-5 pt-4 d-flex">
          <Content className="children-mb-0" content={fields?.description} />
        </div>
        <div className="col-lg-8 pl-lg-5 pt-lg-4 pt-3">
          {fields?.column?.textBalloon && (
            <QuoteBalloon
              icon={false}
              description={fields.column.description}
            />
          )}
          {!fields?.column?.textBalloon && fields?.column?.description && (
            <>
              {fields?.column?.personimage ? (
                <PersonCard className="card mt-lg-5 mt-4">
                  <div className="row justify-content-start">
                    <div className="col-lg-4 d-lg-flex d-none col-2">
                      <PersonImage
                        loading="eager"
                        image={fields?.column?.personimage}
                        alt="person-alt"
                      />
                    </div>
                    <div className="col-lg-8 col-md-9">
                      <div className="pl-lg-3 pl-0 d-flex align-items-center h-100">
                        <ParseContent content={fields?.column?.description} />
                      </div>
                    </div>
                    <div className="col-lg-4 d-none d-md-flex d-lg-none col-3">
                      <PersonImage
                        loading="eager"
                        image={fields?.column?.personimage}
                        alt="person-alt"
                      />
                    </div>
                  </div>
                </PersonCard>
              ) : (
                <div className="card mt-lg-5 mt-4">
                  <ParseContent content={fields?.column?.description} />
                </div>
              )}
            </>
          )}
          {fields?.image && (
            <StyledImage
              loading="eager"
              className="d-lg-block d-none"
              image={fields?.image}
              alt="alt"
            />
          )}
        </div>
      </div>
    </div>
  </BannerWrapper>
)

export default BannerDefault
