import React from 'react'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

interface ImageProps extends GatsbyImageProps {
  className?: string
  image: any
}

const Image: React.FC<ImageProps> = ({
  className = '',
  image,
  alt = '',
  ...rest
}) => {
  if (!image?.localFile?.childImageSharp?.gatsbyImageData) {
    return null
  }
  return (
    <GatsbyImage
      className={className}
      image={image.localFile.childImageSharp.gatsbyImageData}
      alt={alt}
      {...rest}
    />
  )
}

export default Image
