import React from 'react'

// Images
import Quote from 'img/big-quote.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'

interface QuoteBalloonProps {
  icon?: boolean
  description?: string
}

const IconContainer = styled.div`
  position: absolute;
  top: -60px;
  left: 40px;
  z-index: 5;

  @media screen and (max-width: 768px) {
    top: -40px;

    & svg {
      width: 100px;
      height: 100px;
    }
  }

  @media screen and (max-width: 575px) {
    left: 20px;
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 5;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & p {
    line-height: 30px;
    font-size: 25px;

    & b,
    strong {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    & p {
      line-height: 25px;
      font-size: 20px;

      & b,
      strong {
        font-size: 16px;
      }
    }
  }
`

const StyledQuoteBalloon = styled.div<{ icon: boolean }>`
  background-color: ${({ theme }) => theme.colors.light};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  ${({ icon }) =>
    icon
      ? css`
          padding: 120px 80px 60px 80px;
        `
      : css`
          padding: 60px 80px 60px 80px;
        `}
  position: relative;

  &::before {
    height: 140px;
    width: 140px;
    content: '';
    display: block;
    position: absolute;
    border-radius: 20px;
    bottom: -20px;
    left: 40px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    background-color: ${({ theme }) => theme.colors.light};
    transform: rotate(25deg) skew(15deg, 15deg);

    @media screen and (max-width: 575px) {
      height: 80px;
      width: 80px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 40px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.light};
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    ${({ icon }) =>
      icon
        ? css`
            padding: 80px 40px 40px 40px;
          `
        : css`
            padding: 40px 40px 40px 40px;
          `}
  }

  @media screen and (max-width: 400px) {
    ${({ icon }) =>
      icon
        ? css`
            padding: 80px 30px 30px 30px;
          `
        : css`
            padding: 30px 30px 30px 30px;
          `}
  }
`

const QuoteBalloon: React.FC<QuoteBalloonProps> = ({
  icon = true,
  description,
}) => (
  <StyledQuoteBalloon icon={icon}>
    {icon && (
      <IconContainer>
        <Quote />
      </IconContainer>
    )}

    <Content content={description} />
  </StyledQuoteBalloon>
)

export default QuoteBalloon
